import {
  ADD_SCHOOL,
  SCHOOL_PAGE_LOADED,
  DELETE_SCHOOL,
  GET_SCHOOL,
  UPDATE_SCHOOL,
  SUBJECT_LOAD_AND_COURSE
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SCHOOL_PAGE_LOADED: 
      return {
        ...state,
        loading:false,
        pages: action.pages ? action.pages : 0,
        schools: action.data.items,
        current_page: action.data.list.current_page,
        total: action.data.list.total,
        last_page: action.data.list.last_page,
      };
    case SUBJECT_LOAD_AND_COURSE: 
    console.log(action.data);
      return {
        ...state,
        loading:false,
        course: action.data.data.course,
        subjects: action.data.data.subject,
        success: action.success 
      };
    case GET_SCHOOL:
    case UPDATE_SCHOOL:
    case ADD_SCHOOL: 
      return {
        ...state,
        school:action.data,
        action:action.type,
        success:action.success ? true : false,
        schoolErrors: action.errors ? action.errors : null
         
      };
    case DELETE_SCHOOL:
      const itemId = action.itemId
      return {
        ...state,
        schools: state.schools.filter(school => school.id !== itemId)
      };
    default:
      return state;
  }
};
