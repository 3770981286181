import React from "react"; 
import {Link,Redirect} from "react-router-dom";
import { connect } from 'react-redux';
import {ADD_STUDENT, GET_STUDENT, UPDATE_STUDENT,UPLOAD_DOCUMENT} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader";


 const mapStateToProps = state => ({
  ...state.student
});

const mapDispatchToProps = dispatch => ({ 
  onEdit: payload =>
    dispatch({ type: GET_STUDENT, ...payload }),
  onUpload: payload =>
    dispatch({ type: UPLOAD_DOCUMENT, ...payload })
});

class UploadDocument extends React.Component {
    constructor() {
        super();
        this.state = {
            mode:"edit",
            school_id: "",
            redirect:false,
            isLoading:true
        };

         this.onSubmit=() => (ev) => {
            ev.preventDefault(); 
            const formData = new FormData();
            let obj = ['passport_photo','id_proof','prev_qualification','cast_certificate','other_document'];
            this.setState({isLoading:true})
            obj.map(file=>{
                var v = document.getElementById(file).value;
                if(v!="") {
                    formData.append(file, document.getElementById(file).files[0]);     
                }
            });

           const payload = school_api.Students.upload(this.props.school_id,this.props.student_id, formData);

           payload.then(
            res=>{ 
                this.props.onUpload(res) ;
                this.setState({redirect:true});
              }, 

            error=>{this.props.onUpload( error.response.body)} 
          ); 
         }
    }

     
    componentDidMount() {
        if(this.props.school_id) {
             this.setState({ isLoading: true }); 
            school_api.Students.get(this.props.school_id,this.props.student_id).then(res=>this.props.onEdit(res)) ;
        }
    }

    downloadLink = (fileType) => {
        const document = this.props.student.documents;
         
        if(typeof document[fileType]!= 'undefined') {
            return `<a href="${school_api.HOST_ROOT}/student/download/${this.props.student.id}/${fileType}" target="_blank" >Download</a>`;
        }
       return ``;
    }

    shouldComponentUpdate(nextProps){
    if(nextProps.action && this.state.isLoading == true) {
      if(this.props.success && this.state.mode=='edit' && (this.props.action != GET_STUDENT)) {
        this.setState({ isLoading: false ,mode:'done'});
      }else{
        this.setState({ isLoading: false });
      }
    }

    if(nextProps.action == GET_STUDENT && this.state.name == '' ) {
        this.setState({...nextProps.school,isLoading:false});
    }
    return true;
  }

    render() {
        if(this.state.redirect) {
            return <Redirect to={`/students/list`} />
          }
        return (
            
         <form onSubmit={this.onSubmit()} id="studentAdd" encType="multipart/form-data">
              {(this.props.student && this.props.student.student_subject) &&
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                    <div className="card">
                        <div className="header">
                            <h2>
                                Update Marks | <Link to={`/students/list`} className="btn btn-danger waves-effect">Back</Link>
                            </h2>
                        </div>
                        <div className="body">
                            <div>

                             { this.props.studentErrors && 
                                <ul>
                                {this.props.studentErrors && this.props.studentErrors.map( error=>(
                                    <li key={error}>{error} </li>
                                ))}
                                </ul>  
                             }

                            </div>
                           
                            <input type="hidden" id="s_school_id" value={this.props.school_id}   />
                            <label htmlFor="email_address">Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.name}
                                </div>
                            </div>    
                            
                            <label htmlFor="email_address">Session</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {(this.props.student && this.props.student.student_subject) && this.props.student.student_subject.session}
                                </div>
                            </div>
                            {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                                                    <div>
                            <label htmlFor="email_address">Roll No.</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.roll_no}
                                </div>
                            </div>

                            <label htmlFor="email_address">Enrollment No.</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.enrollment_no}
                                </div>
                            </div>
                            </div>
                            }
                            <label htmlFor="email_address">Course</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.student_subject.course.name}
                                </div>
                            </div>
                            <label htmlFor="email_address">Father Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.father_name}
                                </div>
                            </div>
                            
                            <label htmlFor="email_address">Mother Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.mother_name}
                                </div>
                            </div>

                            <label htmlFor="email_address">Mobile</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.mobile}
                                </div>
                            </div>

                            <label htmlFor="email_address">Email</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.email}
                                </div>
                            </div>

                             <label htmlFor="email_address">Address</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.address}
                                </div>
                            </div>


                        </div>
                    </div>
                   
                </div>
              }
              {(this.props.student && this.props.student.student_subject) &&
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="card">
                     <div className="header">
                            <h2>
                               Upload Document
                            </h2> 
                        </div> 
                        <div className="body">
                                 
                            <label htmlFor="email_address">Student Passport Size Photo*</label>
                            <div className="form-group">
                                <div className="form-line">
                                     <input type="file" className="form-control" id="passport_photo" name="passport_photo" />
                                    {this.props.student &&  <div className="content" dangerouslySetInnerHTML={{__html: this.downloadLink('passport_photo')}}></div> }
                                </div>
                            </div>
                            <div style={{"display":"none"}}>
                            <label htmlFor="email_address">Photo ID Proof *</label>
                            <div className="form-group">
                                <div className="form-line">
                                     <input type="file" className="form-control" id="id_proof" name="id_proof" />
                                     {this.props.student &&  <div className="content" dangerouslySetInnerHTML={{__html: this.downloadLink('id_proof')}}></div> }
                                </div>
                            </div>

                            <label htmlFor="email_address">Previous Qualification *</label>
                            <div className="form-group">
                                <div className="form-line">
                                     <input type="file" className="form-control" id="prev_qualification" name="prev_qualification" />
                                     {this.props.student &&  <div className="content" dangerouslySetInnerHTML={{__html: this.downloadLink('prev_qualification')}}></div> }
                                </div>
                            </div>

                            <label htmlFor="email_address">Cast Certificate</label>
                            <div className="form-group">
                                <div className="form-line">
                                     <input type="file" className="form-control" id="cast_certificate" name="cast_certificate" />
                                     {this.props.student &&  <div className="content" dangerouslySetInnerHTML={{__html: this.downloadLink('cast_certificate')}}></div> }
                                </div>
                            </div>

                            <label htmlFor="email_address">Other Document</label>
                            <div className="form-group">
                                <div className="form-line">
                                     <input type="file" className="form-control" id="other_document" name="other_document" />
                                     {this.props.student &&  <div className="content" dangerouslySetInnerHTML={{__html: this.downloadLink('other_document')}}></div> }
                                </div>
                            </div>
                            
                            </div> 
                            <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                        </div>
                    </div>
                </div>
              }
                 <Loader isloading={this.state.isLoading}/>
         </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);;;
