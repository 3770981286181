import React from "react";
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import {ADD_USER, GET_USER, UPDATE_USER} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader";

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: ADD_USER, ...payload }),
  onEdit: payload =>
    dispatch({ type: GET_USER, ...payload }),
  onUpdate: payload =>
    dispatch({ type: UPDATE_USER, ...payload })
});

const mapStateToProps = state => ({
  ...state.user
}); 

class UsersAdd extends React.Component {
    constructor() {
        super();
        this.state = {
            name:'',
            id:'',
            email:'',
            password:'',
            school_id:'',
            status:'Active',
            admit_card:'Yes',
            redirect:false,
            isLoading: false,
            mode:'edit'
        };
        
        this.setName = ev => {
            this.setState({name: ev.target.value });
        };
        
        this.setStatus = ev => {
          this.setState({status: ev.target.value });
      };

      this.setAdmitCard = ev => {
          this.setState({admit_card: ev.target.value });
      };

        this.submitForm = () => ev => {
          ev.preventDefault(); 
          this.setState({isLoading:true});
          let postObject = {...this.state};
          this.saveStudentForm(postObject);
        };

    }

    saveStudentForm = (data) => {
      let payload = '';
      if(this.props.edit_id!="") {
            payload = school_api.Users.update(this.props.edit_id, data);
      }else{
         payload = school_api.Users.create( data);
      }
      payload.then(
      res=>{
              this.dispatchAction(res);
              this.setState({redirect:true});
        },
      error=>
          {                
              this.dispatchAction(error.response.body)
          } 
      );
    }

    dispatchAction = (res) => {
      this.setState({isLoading:true});
      if(this.props.edit_id!="") { 
          this.props.onUpdate(res);
      }else{
           this.props.onSubmit(res);
      }
    }

    changeValue=(variable) => (ev) => {
      const obj = this.state;
      var value = ev.target.value;
      obj[variable] = value;
      this.setState(obj);
  }

    componentDidMount() {
        if(this.props.edit_id) {
             this.setState({ isLoading: true }); 
            school_api.Users.get(this.props.edit_id).then(res=>this.props.onEdit(res)) ;
        }
    }

   shouldComponentUpdate(nextProps){
    if(nextProps.action && this.state.isLoading == true) {
      if(this.props.success && this.state.mode=='edit' && (this.props.action != GET_USER)) {
           this.setState({ isLoading: false ,mode:'done'});
      }else{
        this.setState({ isLoading: false });
      }
    }

  if(this.props.edit_id!="" && nextProps.action == GET_USER && this.state.id != nextProps.data.id ) {
     
      this.setState({...nextProps.data,isLoading:false});
    }
    return true;
  }

    render() {
         if(this.state.redirect) {
          return <Redirect to='/users/list' />
        }
        const active = this.state.status == 'Active' ?true:false;
        const inactive = this.state.status == 'Inactive' ?true:false;

        const admit_active = this.state.admit_card == 'Yes' ?true:false;
        const admit_inactive = this.state.admit_card == 'No' ?true:false;
        return (
            
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Form | <Link to="/users/list" className="btn btn-danger waves-effect">Back</Link>
                            </h2>
                            
                        </div>
                        <div className="body">
                            <div>
                            { this.props.dataErrors && 
                                <ul>
                                {this.props.dataErrors && this.props.dataErrors.map( error=>(
                                    <li key={error}>{error} </li>
                                ))}
                                </ul>  
                             }
                            </div>
                            <form onSubmit={this.submitForm()}>
                                <label htmlFor="email_address">Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="name" value={this.state.name} onChange={this.changeValue('name')} className="form-control" placeholder="Enter your name" />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Email</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="email" value={this.state.email} onChange={this.changeValue('email')} className="form-control" placeholder="Password" />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Password</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="password" id="optional_subject" value={this.state.password} onChange={this.changeValue('password')} className="form-control" placeholder="Password" />
                                    </div>
                                </div>

                                <label htmlFor="email_address">School</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <select className="form-control" value={this.state.school_id} name="school_id" onChange={this.changeValue('school_id')} >
                                          <option value="">Select School</option>
                                          {this.props.dashboard.school_list && this.props.dashboard.school_list.map(obj=>(
                                            <option key={obj.id} value={obj.id}>{obj.name}</option>
                                          ))}
                                        </select>
                                    </div>
                                </div>
                                <label htmlFor="password">Admit Card Download</label>
                                <div className="form-group">
                                    <div  >
                                        <input type="radio" name="radio" name="admit_card" checked={admit_active}  value="Yes"
                                        onChange={this.setAdmitCard} />Yes
                                         &nbsp;
                                         <input type="radio" name="radio" name="admit_card" checked={admit_inactive} value="No"
                                        onChange={this.setAdmitCard} />No
                                    </div>
                                </div> 
                                <label htmlFor="password">Status</label>
                                <div className="form-group">
                                    <div  >
                                        <input type="radio" name="radio" name="status" checked={active}  value="Active"
                                        onChange={this.setStatus} />Active
                                         &nbsp;
                                         <input type="radio" name="radio" name="status" checked={inactive} value="Inactive"
                                        onChange={this.setStatus} />Inactive
                                    </div>
                                </div>          
                                <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                            </form>
                        </div>
                    </div>
                     <Loader isloading={this.state.isLoading}/>
                </div>
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdd);
