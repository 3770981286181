import React from "react";
import UsersList from "./UsersList";
import UsersAdd from  "./UsersAdd";
import ChangePassword from "./ChangePassword";
import { Switch, Route } from "react-router-dom"; 
 

class Users extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!", 
        };
    }

    componentDidMount() {
         
    }

    render() {
        
        return (
        <section className="content">
            <div className="container-fluid">
            <div className="block-header">
                <h2>Users</h2>
            </div> 
            <div className="row clearfix">
            {this.props.match.params.action == 'list' && <UsersList/>  }
            {this.props.match.params.action == 'add' && <UsersAdd dashboard={this.props.dashboard} edit_id='' /> }
            {this.props.match.params.action == 'change_password' && <ChangePassword dashboard={this.props.dashboard} edit_id='' /> }
            {this.props.match.params.action == 'edit' && <UsersAdd dashboard={this.props.dashboard}  edit_id={this.props.match.params.id}  /> }
            </div>
            </div>
        </section>
        );
    }
}

export default Users;
