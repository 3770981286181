import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {STUDENT_PAGE_LOADED,ADD_STUDENT,DELETE_SCHOOL} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.student 
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => {
     console.log(payload);
     payload.then(res=> dispatch({ type: STUDENT_PAGE_LOADED, data:res[0] }) )
  },  
     
});

class PrintMigration extends React.Component {
    constructor() {
        super();
       this.state = {
            title: "Welcome to React SSR!",
            s_roll_number:'',
            print_date:'',
            totalMarks:'',
            resutlMarks:'',  
            print_date2:"",
            print_date_held:new Date(),
            isLoading: false,
            student:{},
            page:0,
            loginClass: ''
        };
        
        
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }

    changeDate=() => (ev) => {
        const obj = this.state;
        obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date2'] = d.getDate()+"-"+(d.getMonth()+1)+"-"+d.getFullYear();; 
        this.setState(obj);  
    }

    heldDate=() => (ev) => {
        const obj = this.state;
        //obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date_held'] = d;
        this.setState(obj);  
    }
    
    
     
    submitForm=() => (ev) => {
        ev.preventDefault();
        if(this.state.s_roll_number!=""){
            this.generateAdmitCard(this.state.s_roll_number);
        }else{
            alert("Please enter roll number.");
        }
    }

    printPdfMarksheet= () =>{
        document.getElementById("print_date_input").style.display="none";
        document.getElementById("print_date_held").style.display="none";
        var printContents = document.getElementById("printDiv").innerHTML;
        var originalContents = document.body.innerHTML;
   
        document.body.innerHTML = printContents;
   
        window.print();
             
        document.body.innerHTML = originalContents;
    }

    componentDidMount() {
        //this.loadData(0);
    }
    generateAdmitCard = (studentId) => {
        this.setState({ isLoading: true }); 
        school_api.Students.getStudentByRollNumber(studentId).then(res => {
            let totalMark=0;
            let resutl="Fail";
            if(res.success) {
                var d =  new Date(res.data.dob);                     
                res.data.dob = d.getDate()+"-"+(d.getMonth()+1)+"-"+d.getFullYear();

                if(res.data.subjects.length>0){
                      resutl='Pass';

                    res.data.subjects.map((subject)=>{
                        totalMark = totalMark+parseInt(subject.marks) ;
                        if(subject.subject.passing_mark>parseInt(subject.marks)) {
                            resutl="Fail";
                        }
                    })
                }

                var dobObj = new Date(res.data.dob);


                this.setState({ isLoading: false,student:res.data,totalMarks:totalMark,resutlMarks:resutl });
            }else{
                this.setState({ isLoading: false ,student:{},totalMarks:totalMark,resutlMarks:resutl });
                alert("Student does not exist.");
            }
        });
    }
     
    shouldComponentUpdate(nextProps){ 
        
        return true;
    }
    printMarks = () => {
        
        return <div>Hiiiii</div>;
    }
    render() {
         
        
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const d = this.state.print_date_held;
        return ( 
           <div>
               <form onSubmit={this.submitForm()}  >
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Search
                            </h2>
                        </div>
                        <div className="body">
                            
                            <div className="row clearfix">
                                <div className="col-md-3">
                                    <label htmlFor="email_address">Roll Number</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_roll_number" className="form-control" onChange={this.changeValue('s_roll_number')} value={this.state.s_center} placeholder="Enter Roll Number" />
                                        </div>
                                    </div>
                                </div> 

                            </div>
                            <button class="btn btn-primary waves-effect" type="submit">Search</button>
                        </div>
                    </div>
            </div>
            </form>
            {this.state.student.id && 
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

<div className="card">                            
<div className="body" style={{height: "500px"}}>

<div className="table-responsive">
<div id="printDiv">

 
{/*<img src="/img/secondary-mark-sheet.jpg" />*/}
<table width="800" border="0" cellspacing="0" cellpadding="0" style={{fontFamily:"'Charm', cursive", height:"100%", position:"absolute", top:0}}>
  <tr>
    <td>&nbsp;</td>
    <td colspan="2" style={{paddingTop: "60px", verticalAlign:"top", paddingLeft:"100px"}}>{this.state.s_roll_number}</td>
    <td>&nbsp;</td>
    <td colspan="2" style={{paddingTop: "40px", verticalAlign:"top", paddingLeft:"490px"}}>{this.state.student.enrollment_no}</td>
  </tr>
  

 
  
  <tr>
    <td colspan="6" style={{paddingTop:"0px"}}>
    
   <table width="100%" border="0" cellspacing="0" cellpadding="0" style={{width: "89%",margin: "0 auto", textAlign: "left", fontSize: "14px"}}>
    
  <tr>
    <td colspan="6" style={{padding:"5px 5px 5px 20px", width:"11%", textAlign:"left"}}>
    <p style={{padding:"0 20px", lineHeight:"35px", fontSize:"18px", textAlign:"justify"}}>
    <strong>This is Certified that</strong> {this.state.student.name} 
    S/O  D/O Mr/Ms {this.state.student.father_name} has passed <strong>{this.state.student.coursename && this.state.student.coursename.name}</strong><input type="date" value="" id="print_date_held" onChange={this.heldDate()}  /> held in <strong>{monthNames[d.getMonth()]} – {d.getFullYear()}</strong> under {this.state.student.school.name} with <strong>Roll No. {this.state.s_roll_number}</strong>. His/Her date of birth is <strong>{this.state.student.dob}</strong> according to the records of {this.state.student.school.name}.
    </p>
    </td>
  </tr>
  </table>
    
    
    </td>
    
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td colspan="2" style={{position: "relative"}}><span style={{paddingLeft:"100px",position: "absolute",width: "450px", top:"-10px"}}><input type="date" value={this.state.print_date} id="print_date_input" onChange={this.changeDate()}  /> {this.state.print_date2}</span></td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    
  </tr>
</table>
</div>
<div className="clearfix"></div>
<button class="btn btn-primary waves-effect" type="button" onClick={ ()=> this.printPdfMarksheet()}>Print</button>
 </div>
        </div>
    </div>
    <Loader isloading={this.state.isLoading}/>
</div>
}

</div>
         
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintMigration);


