import {
  ADD_STUDENT,
  STUDENT_PAGE_LOADED,
  DELETE_STUDENT,
  GET_STUDENT,
  UPDATE_MARKS,
  UPDATE_STUDENT,
  STUDENT_LOAD_AND_COURSE,
  UPLOAD_DOCUMENT,
  GET_STUDENT_INFO
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case STUDENT_PAGE_LOADED:
      console.log(action) ;
      return {
        ...state,
        loading:false,
        pages: action.pages ? action.pages : 0,
        datas: action.data.items,
        current_page: action.data.list.current_page,
        total: action.data.list.total,
        last_page: action.data.list.last_page,
      };
    case GET_STUDENT_INFO:
        return {
          ...state,
           student:action.data,
          action:action.type,
          success:action.success ? true : false,
          studentErrors: action.errors ? action.errors : null         
        }; 
    case GET_STUDENT:
    case UPLOAD_DOCUMENT:
    case UPDATE_MARKS:
    case UPDATE_STUDENT:
    case ADD_STUDENT:
      if(action.errors) {
        return {
          ...state,
          // student:action.data,
          //action:action.type,
          success:action.success ? true : false,
          studentErrors: action.errors ? action.errors : null         
        };
      }
      return {
        ...state,
       	student:action.data,
        action:action.type,
        success:action.success ? true : false,
        studentErrors: action.errors ? action.errors : null         
      };
    case DELETE_STUDENT:
      const itemId = action.itemId
      return {
        ...state,
        students: state.students.filter(school => school.id !== itemId)
      };
    default:
      return state;
  }
};
