import React from "react";
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import {ADD_SUBJECT, GET_SUBJECT, UPDATE_SUBJECT} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader";

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: ADD_SUBJECT, ...payload }),
  onEdit: payload =>
    dispatch({ type: GET_SUBJECT, ...payload }),
  onUpdate: payload =>
    dispatch({ type: UPDATE_SUBJECT, ...payload })
});

const mapStateToProps = state => ({
  ...state.subject
});
 

class SubjectAdd extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: '',
            name:'',
            passing_mark:'',
            course_id:'',
            s_code:'',
            status:'Active',
            practical:'No',
            redirect:false,
            mode:'edit' 
        };

        this.setName = ev => {
            this.setState({name: ev.target.value });
        };

        this.setStatusC = ev => { 
          this.setState({status: ev.target.value });
      };

      this.setPractical = ev => { 
          this.setState({practical: ev.target.value });
      };
        
        this.setS_code = ev => {
          this.setState({s_code: ev.target.value });
      };
      this.setCourse_id = ev => {
        this.setState({course_id: ev.target.value });
      };
      this.setPassing_mark = ev => {
        this.setState({passing_mark: ev.target.value });
      };

        this.submitForm = () => ev => { 
          ev.preventDefault(); 
          
          this.setState({isLoading:true});
         if(this.props.sid) {
            const payload = school_api.Subjects.update(this.props.sid, this.state);
              payload.then(
                res=>{ 
                    this.props.onUpdate(res);
                    this.setState({redirect:true}) ;
                  }, 

                error=>{this.props.onUpdate( error.response.body)} 
              ); 
         }else{
             const payload = school_api.Subjects.create(this.state);
              payload.then(
                res=>{ 
                    this.props.onSubmit(res) ;
                    this.setState({redirect:true}) ;
                  }, 

                error=>{this.props.onSubmit( error.response.body)} 
              ); 
         }
        };
    }

    componentDidMount() {
        if(this.props.sid) {
            this.setState({ isLoading: true }); 
            school_api.Subjects.get(this.props.sid).then(res=>this.props.onEdit(res)) ;
        }
    }

   shouldComponentUpdate(nextProps){
    if(nextProps.action && this.state.isLoading == true) {
      if(this.props.success && this.state.mode=='edit' && (this.props.action != GET_SUBJECT)) {
           this.setState({ isLoading: false ,mode:'done'});
      }else{
        this.setState({ isLoading: false });
      }
     
    }

  if(this.props.sid!="" && nextProps.action == GET_SUBJECT && this.state.id != nextProps.data.id ) {
      this.setState({...nextProps.data,isLoading:false});
    }
    return true;
  }
    render() {
          if(this.state.redirect) {
                  return <Redirect to='/subjects/list' />
          }
        const active = this.state.status == 'Active' ?true:false;
        const inactive = this.state.status == 'Inactive' ?true:false;

        const practical_yes = this.state.practical == 'Yes' ?true:false;
        const practical_no = this.state.practical == 'No' ?true:false;

        return (
              

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Form | <Link to="/subjects/list" className="btn btn-danger waves-effect">Back</Link>
                            </h2>
                            
                        </div>
                        <div className="body">
                            <div>
                                { this.props.dataErrors && 
                                    <ul>
                                    {this.props.dataErrors && this.props.dataErrors.map( error=>(
                                        <li key={error}>{error} </li>
                                    ))}
                                    </ul>  
                                 }
                            </div>

                            <form onSubmit={this.submitForm()}>
                                <label for="email_address">Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="name" value={this.state.name} onChange={this.setName} className="form-control" placeholder="Enter your name" />
                                    </div>
                                </div>

                                <label for="email_address">Subject Code</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_code" value={this.state.s_code} onChange={this.setS_code} className="form-control" placeholder="Enter subject code" />
                                    </div>
                                </div>
                                <label for="email_address">Passing Mark</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="s_passing_mark" value={this.state.passing_mark} onChange={this.setPassing_mark} className="form-control" placeholder="Enter passing marks" />
                                    </div>
                                </div>
                                <label for="email_address">Select Course</label>
                                <div className="form-group">
                                    <div className="form-line">
                                      <select className="form-control" value={this.state.course_id} name="course_id" onChange={this.setCourse_id} >
                                            <option value="">Select Course</option>
                                            { (this.props.dashboard && this.props.dashboard.course_subject) && this.props.dashboard.course_subject.course.map(obj=>(
                                              <option key={obj.id} value={obj.id}>{obj.name}</option>
                                            ))}
                                      </select>
                                    </div>
                                </div>
                                <label htmlFor="password">Practical</label>
                                <div className="form-group">
                                    <div  >
                                        <input type="radio" name="radio" name="practical" checked={practical_yes}  value="Yes"
                                        onChange={this.setPractical} />Yes
                                         &nbsp;
                                         <input type="radio" name="radio" name="practical" checked={practical_no} value="No"
                                        onChange={this.setPractical} />No
                                    </div>
                                </div>
                                <label htmlFor="password">Status</label>
                                <div className="form-group">
                                    <div  >
                                        <input type="radio" name="radio" name="status" checked={active}  value="Active"
                                        onChange={this.setStatusC} />Active
                                         &nbsp;
                                         <input type="radio" name="radio" name="status" checked={inactive} value="Inactive"
                                        onChange={this.setStatusC} />Inactive
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                            </form>
                        </div>
                    </div>
                     <Loader isloading={this.state.isLoading}/>
                </div>
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectAdd);;
