import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {STUDENT_PAGE_LOADED,ADD_STUDENT,DELETE_SCHOOL} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.student 
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => {
     console.log(payload);
     payload.then(res=> dispatch({ type: STUDENT_PAGE_LOADED, data:res[0] }) )
  },  
     
});

class PrintMarkSheet extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            s_roll_number:'',
            print_date:'',
            totalMarks:'',
            resutlMarks:'',  
            print_date2:"",
            print_date_held:new Date(),
            isLoading: false,
            student:{},
            page:0,
            loginClass: ''
        };
        
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }

    changeDate=() => (ev) => {
        const obj = this.state;
        obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date2'] = d.getDate()+"-"+(d.getMonth()+1)+"-"+d.getFullYear();; 
        this.setState(obj);  
    }

    heldDate=() => (ev) => {
        const obj = this.state;
        //obj['print_date'] =ev.target.value;
        var d =  new Date(ev.target.value);
        obj['print_date_held'] = d;
        this.setState(obj);  
    }
    
    
     
    submitForm=() => (ev) => {
        ev.preventDefault();
        if(this.state.s_roll_number!=""){
            this.generateAdmitCard(this.state.s_roll_number);
        }else{
            alert("Please enter roll number.");
        }
    }

    printPdfMarksheet= () =>{
        document.getElementById("print_date_input").style.display="none";
        document.getElementById("print_date_held").style.display="none";
        var printContents = document.getElementById("printDiv").innerHTML;
        var originalContents = document.body.innerHTML;
   
        document.body.innerHTML = printContents;
   
        window.print();
             
        document.body.innerHTML = originalContents;
    }

    componentDidMount() {
        //this.loadData(0);
    }

    generateAdmitCard = (studentId) => {
        this.setState({ isLoading: true }); 
        school_api.Students.getStudentByRollNumber(studentId).then(res => {
            let totalMark=0;
            let resutl="Fail";
            if(res.success) {
                res.data.documents = res.data.documents!="" ? JSON.parse(res.data.documents) : {};
                 var d =  new Date(res.data.dob); 
                    
                    res.data.dob = d.getDate()+"-"+(d.getMonth()+1)+"-"+d.getFullYear();

                if(res.data.subjects.length>0){
                      resutl='Pass';
                    res.data.subjects.map((subject,index)=>{
                       
                        
                        if(subject.subject.practical=="Yes") {
                            res.data.subjects[index].practical_mark = isNaN(res.data.subjects[index].practical_mark) || res.data.subjects[index].practical_mark==null ? 0 : res.data.subjects[index].practical_mark;
                            
                            res.data.subjects[index].practical_mark=parseInt(res.data.subjects[index].practical_mark);
                            res.data.subjects[index].total_subject = parseInt(subject.marks)+parseInt(subject.practical_mark);

                            res.data.subjects[index].sub_result = res.data.subjects[index].total_subject>=parseInt(subject.subject.passing_mark) ? 'Pass' : 'Fail';
                            console.log(res.data.subjects[index]);
                        }else{
                            res.data.subjects[index].total_subject = parseInt(subject.marks);

                            res.data.subjects[index].sub_result = res.data.subjects[index].total_subject>=parseInt(subject.subject.passing_mark) ? 'Pass' : 'Fail';
                        }

                        if(res.data.subjects[index].total_subject>parseInt(subject.passing_mark)) {
                            resutl="Fail";
                        }
                         totalMark = totalMark+res.data.subjects[index].total_subject ;
                        
                    })
                }
                this.setState({ isLoading: false,student:res.data,totalMarks:totalMark,resutlMarks:resutl });
            }else{
                this.setState({ isLoading: false ,student:{},totalMarks:totalMark,resutlMarks:resutl });
                alert("Student does not exist.");
            }
        });
    }
     
    shouldComponentUpdate(nextProps){ 
        
        return true;
    }
    printMarks = () => {
        
        return <div></div>;
    }
    render() {

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const d = this.state.print_date_held;
        return ( 
           <div>
               <form onSubmit={this.submitForm()}  >
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Search
                            </h2>
                        </div>
                        <div className="body">
                            
                            <div className="row clearfix">
                                <div className="col-md-3">
                                    <label htmlFor="email_address">Roll Number</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_roll_number" className="form-control" onChange={this.changeValue('s_roll_number')} value={this.state.s_center} placeholder="Enter Roll Number" />
                                        </div>
                                    </div>
                                </div> 

                            </div>
                            <button class="btn btn-primary waves-effect" type="submit">Search</button>
                        </div>
                    </div>
            </div>
            </form>
            {this.state.student.id && 
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

<div className="card">                            
<div className="body">

<div className="table-responsive">
<div id="printDiv">

 
{/*<img src="/img/secondary-mark-sheet.jpg" />*/}
<table  width="800" border="0" cellSpacing="0" cellPadding="0" style={{fontFamily:"Verdana, Geneva, sans-serif", height:"100%", position:"relative", top:0}}>
  <tbody>
  <tr>
    <td>&nbsp;</td>
    <td colspan="2" style={{paddingTop: "50px", verticalAlign:"middle", paddingLeft:"100px"}}>{this.state.student.roll_no}</td>
    <td>&nbsp;</td>
    <td colspan="2" style={{paddingTop: "0px", verticalAlign:"top", paddingLeft:"450px"}}><span style={{marginTop:"43px", marginLeft:"-15px" , position:"absolute" , verticalAlign: "bottom"}}>{this.state.student.enrollment_no}</span></td>
  </tr>  
  
  <tr>
    <td>&nbsp;</td>
    <td colspan="5" valign="top" height="30" style={{paddingTop:"270px", paddingLeft:"300px"}}>{this.state.student.name} 

    <p style={{marginTop:"200px", left:"320px", fontWeight:"normal", position:"absolute"}}><input type="date" value="" id="print_date_held" onChange={this.heldDate()}  />held in {monthNames[d.getMonth()]} – {d.getFullYear()}</p></td>
    <td rowspan="3"><img src={"http://schoolapi.ichse.ac.in/student/download/"+this.state.student.id+"/passport_photo"} style={{marginTop:"75px", left:"600px", width:"110px", height:"120px", position:"absolute"}} /> 

    </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td colspan="5" valign="top" height="30" style={{paddingTop:"22px", paddingLeft:"300px"}}>{this.state.student.father_name}</td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td colspan="5" valign="top" height="30" style={{paddingTop:"25px", paddingLeft:"300px"}}>{this.state.student.mother_name}</td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td colspan="5" valign="top" height="30" style={{paddingTop:"47px", paddingLeft:"300px"}}>{this.state.student.dob}</td>
  </tr>
  <tr>
    <td colSpan="6" style={{paddingTop:"220px", verticalAlign: "top"}}>
    {this.state.student.subjects.length>0 &&
   <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{width: "87%",textAlign:"left",fontSize: "14px", marginLeft:"20px"}}>
    <tbody>
     
    {this.state.student.subjects.length>0 && this.state.student.subjects.map((subject) => 

    
  <tr>
    <td style={{padding:"4px 5px 4px 0px", width:"11%", textAlign:"right"}}>{subject.subject.s_code}</td>
    <td style={{padding:"4px 5px 4px 40px", width:"40%", textAlign:"left"}}>{subject.subject.name}</td>
	 <td style={{padding:"4px", width:"11%", textAlign:"left"}}>100</td>
    <td style={{padding:"4px", width:"11%", textAlign:"left"}}>{subject.marks}</td>
    <td style={{padding:"4px", width:"11%", textAlign:"left"}}>{ subject.subject.practical=="Yes" &&subject.practical_mark}</td>
    <td style={{padding:"4px", width:"11%", textAlign:"left"}}> {subject.total_subject}</td>
    <td style={{padding:"4px 4px 4px 15", width:"11%", textAlign:"center", position:"absolute", marginLeft:"0px"}}>{subject.sub_result}</td>
  </tr>
   ) }

     {this.state.student.subjects.length<6 &&

    
  <tr>
    <td style={{padding:"4px 5px 4px 0px", width:"11%", textAlign:"right"}}>&nbsp;</td>
    <td style={{padding:"4px 5px 4px 40px", width:"40%", textAlign:"left"}}>&nbsp;</td>
	<td style={{padding:"4px", width:"11%", textAlign:"left"}}></td>
    <td style={{padding:"4px", width:"11%", textAlign:"left"}}>&nbsp;</td>
    <td style={{padding:"4px", width:"11%", textAlign:"left"}}>&nbsp;</td>
    <td style={{padding:"4px", width:"11%", textAlign:"left"}}> &nbsp;</td>
    <td style={{padding:"4px 4px 4px 15px", width:"11%", textAlign:"center", position:"absolute", marginLeft:"0px"}}>&nbsp;</td>
  </tr>
    }
  
  <tr>
    <td style={{padding:"5px", width:"11%", textAlign:"left", height:"10px"}} colspan="6">&nbsp;</td>
    
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td colspan="2">&nbsp;</td>
    <td colspan="2" style={{paddingLeft:"20px", paddingTop:"40px"}}><strong style={{position:"absolute", marginTop:"-21px" , marginLeft:"100px"}}>{this.state.totalMarks}</strong></td>
    </tr>
    <tr>
    <td style={{padding:"4px 5px 4px 0px", width:"11%", textAlign:"left"}}>&nbsp;</td>
    <td style={{padding:"15px 5px 4px 40px", width:"11%", textAlign:"left"}}><p style={{position:"absolute", marginTop:"25px", marginLeft:"100px"}}>{this.state.student.school.name} {this.state.student.school.pin_code}</p></td>
    <td style={{padding:"4px", width:"11%", textAlign:"center"}}></td>
    <td style={{padding:"4px", width:"11%", textAlign:"center"}}></td>
    <td style={{padding:"4px", width:"11%", textAlign:"center"}}></td>
    <td style={{padding:"4px", width:"11%", textAlign:"center"}}></td>
  </tr>
  
  <tr>
    <td style={{padding:"4px 5px 4px 20px", width:"11%", textAlign:"left"}}>&nbsp;</td>
    <td style={{padding:"4px 5px 4px 30px", width:"40%", textAlign:"left"}}></td>
    <td style={{padding:"4px", width:"11%", textAlign:"center"}}></td>
    <td style={{padding:"4px", width:"11%", textAlign:"center"}}></td>
    <td style={{padding:"4px", width:"11%", textAlign:"center"}}></td>
    <td style={{padding:"0px 0 0", width:"11%", textAlign:"center"}}><p style={{position:"absolute", left:"166px" , marginTop:"35px" , fontWeight:"bold"}}>{this.state.resutlMarks}</p></td>
  </tr>
  </tbody>
</table> 
}
    </td>
    
  </tr>



  <tr>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td><p style={{marginTop:"0px", bottom:"-50px", marginBottom:"0px", left:"120px", fontWeight:"normal", position:"absolute"}}>
    <input type="date" value={this.state.print_date} id="print_date_input" onChange={this.changeDate()}  /> {this.state.print_date2} </p> </td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td></td>
    <td>&nbsp;</td>
  </tr>
  </tbody>
</table>


	
</div>
<div className="clearfix"></div>
<button class="btn btn-primary waves-effect" type="button" onClick={ ()=> this.printPdfMarksheet()}>Print</button>
 </div>
        </div>
    </div>
    <Loader isloading={this.state.isLoading}/>
</div>
}

</div>
         
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintMarkSheet);
