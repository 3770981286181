import React from "react"; 
import {Link,Redirect} from "react-router-dom";
import { connect } from 'react-redux';
import {ADD_STUDENT, GET_STUDENT, UPDATE_STUDENT,UPLOAD_DOCUMENT,UPDATE_MARKS} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader";


 const mapStateToProps = state => ({
  ...state.student
});

const mapDispatchToProps = dispatch => ({ 
onEdit: payload =>
    dispatch({ type: GET_STUDENT, ...payload }),
updateMarks: payload =>
    dispatch({ type: UPDATE_MARKS, ...payload })
});

class UpdateMarks extends React.Component {
    constructor() {
        super();
        this.state = {
            mode:"edit",
            name:'',
            school_id: "",
            redirect:false,
            isLoading:false,
            subjects:[]
        };

         this.onSubmit=() => (ev) => {
            ev.preventDefault(); 
            const formData = new FormData();
            let obj = this.props.student.subjects;
            let subjectMarks = [];
            obj.map(mark=>{
                let practical = null;
                if(document.getElementById(`subject_practical_${mark.id}`)){
                    practical = document.getElementById(`subject_practical_${mark.id}`).value;
                } 
                subjectMarks.push({id:mark.id,practical:practical,value:document.getElementById(`subject_${mark.id}`).value});
            }
            );

            this.setState({ isLoading: true }); 
           const payload = school_api.Students.updateMarks(this.props.school_id,this.props.student_id, { ...this.state, subjects:subjectMarks});

           payload.then(
            res=>{ 
                this.props.updateMarks(res);
                this.setState({redirect:true});
              }, 

            error=>{this.props.updateMarks( error.response.body)} 
          ); 
         }
    }

    changeValue=(index) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        const subjects = this.state.subjects;
        subjects[index].marks = value;
        this.setState({subjects:subjects});  
    }
    practicalValue=(index) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        const subjects = this.state.subjects;
        subjects[index].practical_mark = value;
        this.setState({subjects:subjects});  
    }
    componentDidMount() {
        if(this.props.student_id && this.state.isLoading==false) {
             this.setState({ isLoading: true }); 
            school_api.Students.get(this.props.school_id,this.props.student_id).then(res=>this.props.onEdit(res)) ;
        }
    }

    shouldComponentUpdate(nextProps){
        
    if(nextProps.action && this.state.isLoading == true) {         
      if(this.props.success && this.state.mode=='edit' && (this.props.action != GET_STUDENT)) {
        this.setState({ isLoading: false ,mode:'done'});
      }else{
        this.setState({ isLoading: false });
      }
    }
   
    if(nextProps.action == GET_STUDENT && this.state.name == '' ) {
        console.log(nextProps.student.subjects);
        this.setState({...nextProps.school,name:nextProps.student.name,subjects:nextProps.student.subjects,isLoading:false});
    }
    return true;
  }

    render() {
        if(this.state.redirect) {
            return <Redirect to={`/students/list`} />
          }
        return (
        <form onSubmit={this.onSubmit()} id="studentAdd" encType="multipart/form-data">
               {(this.props.student && this.props.student.student_subject) &&
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                    <div className="card">
                        <div className="header">
                            <h2>
                                Upload Marks | <Link to={`/students/list`} className="btn btn-danger waves-effect">Back</Link>
                            </h2>
                        </div>
                        <div className="body">
                            <div>

                             { this.props.studentErrors && 
                                <ul>
                                {this.props.studentErrors && this.props.studentErrors.map( error=>(
                                    <li key={error}>{error} </li>
                                ))}
                                </ul>  
                             }

                            </div>
                           
                            <input type="hidden" id="s_school_id" value={this.props.school_id}   />
                            <label htmlFor="email_address">Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.name}
                                </div>
                            </div>    
                            
                            <label htmlFor="email_address">Session</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.student_subject.session}
                                </div>
                            </div>
                            {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                                                    <div>
                            <label htmlFor="email_address">Roll No.</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.roll_no}
                                </div>
                            </div>

                            <label htmlFor="email_address">Enrollment No.</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.enrollment_no}
                                </div>
                            </div>
                                </div>
                            }
                            <label htmlFor="email_address">Course</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {(this.props.student && this.props.student.student_subject) && this.props.student.student_subject.course.name}
                                </div>
                            </div>
                            <label htmlFor="email_address">Father Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.father_name}
                                </div>
                            </div>
                            
                            <label htmlFor="email_address">Mother Name</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.mother_name}
                                </div>
                            </div>

                            <label htmlFor="email_address">Mobile</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.mobile}
                                </div>
                            </div>

                            <label htmlFor="email_address">Email</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.email}
                                </div>
                            </div>

                             <label htmlFor="email_address">Address</label>
                            <div className="form-group">
                                <div className="form-line">
                                     {this.props.student && this.props.student.address}
                                </div>
                            </div>


                        </div>
                    </div>
                   
                </div>
               }
               {(this.props.student && this.props.student.student_subject) &&
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="card">
                     <div className="header">
                            <h2>
                              Update Subject Marks
                            </h2> 
                        </div> 
                        <div className="body">
                            {this.state.subjects && this.state.subjects.map((obj,index) => (
                                 <div>
                                 { obj.subject.practical == 'Yes' &&
                                  <div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label htmlFor="email_address">{obj.subject.name}</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                             <input type="number" min="0" max="100" className="form-control" onChange={this.changeValue(index)} value={ obj.marks!==null && obj.marks } name={`subject_${obj.id}`}   id={`subject_${obj.id}`}  />
                                        </div>
                                    </div>
                                    </div>
                                   <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label htmlFor="email_address">Practical</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                             <input type="number" min="0" max="100" className="form-control" onChange={this.practicalValue(index)} value={ obj.practical_mark!==null && obj.practical_mark } name={`subject_practical_${obj.id}`}   id={`subject_practical_${obj.id}`}  />
                                        </div>
                                    </div>
                                </div>
                                </div> 
                               
                                }

                                { obj.subject.practical == 'No' &&
                                <div>
                                    <label htmlFor="email_address">{obj.subject.name}</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                             <input type="number" min="0" max="100" className="form-control" onChange={this.changeValue(index)} value={ obj.marks!==null && obj.marks } name={`subject_${obj.id}`}   id={`subject_${obj.id}`}  />
                                        </div>
                                    </div>
                                </div>
                                }
                                </div>
                            ))}     
                            
                                
                            <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                        </div>
                    </div>
                </div>
               }
                 <Loader isloading={this.state.isLoading}/>
               </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMarks);;;
