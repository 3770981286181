import React from "react"; 
import Popup from "./Popup";

class Dailogs extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: ''
        };
        
    }

    componentDidMount() { 
    }

    render() {
         
        return (
            <div>
                <Popup/>
            </div>
        );
    }
}

export default Dailogs;
