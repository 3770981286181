import React from "react";
import SubjectList from "./SubjectList";
import SubjectAdd from  "./SubjectAdd";
import { Switch, Route } from "react-router-dom"; 
 

class Subjects extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: '',
            action:''
        };
        
    }

    componentDidMount() {
        console.log(this.props);
    }

      

    render() {
        
        return (
        <section className="content">
            <div className="container-fluid">
            <div className="block-header">
                <h2>Subjects</h2>
            </div> 
            <div className="row clearfix">
            {this.props.match.params.action == 'list' && <SubjectList/>  }
            {this.props.match.params.action == 'add' && <SubjectAdd action={this.state.action} dashboard={this.props.dashboard} /> }
            {this.props.match.params.action == 'edit' && <SubjectAdd action={this.state.action} dashboard={this.props.dashboard} sid={this.props.match.params.id} /> }
            </div>
            </div>
        </section>
        );
    }
}

export default Subjects;
