import React from "react"; 
 

class Loader extends React.Component {
    constructor() {
        super();
        this.state = {
            current_page:0,
            last_page:0,
            total:0
        };
        
    }

    sendToPagination = (page) => {
        if(this.props.current_page!=page) {
            this.props.loadData(page)
        }
    }

    sendNextPage = () => {
      let np = this.props.current_page +1;
       
      if(this.props.last_page >= np)   {
          this.sendToPagination(np);
      }
    }

    sendPrevPage = () => {
        let np = this.props.current_page -1;
      if(np>0 && this.props.last_page>= np)   {
          this.sendToPagination(np);
      }
    }

    componentDidMount() { 

    }

    render() {
         
       if(this.props.last_page > 1) {
        return ( 
            <ul class="pagination">
            <li>
                <a   onClick={()=>this.sendPrevPage()}  class="waves-effect">
                    <i class="material-icons">chevron_left</i>
                </a>
            </li>
            { [...Array(this.props.last_page)].map((x, i) => (
                 <li><a   class="waves-effect" onClick={()=>this.sendToPagination((i+1))} >{i+1}</a></li>
            ))}
             
            <li>
                <a   onClick={()=>this.sendNextPage()} class="waves-effect">
                    <i class="material-icons">chevron_right</i>
                </a>
            </li>
            </ul>
        );
       }
       return '';
     
    }
}

export default Loader;
