import React from 'react';
import {Link,Redirect} from 'react-router-dom';

class Menu extends React.Component {
     constructor() {
        super();
        this.state = {
            logout: false, 
        };
    }
    onItemClick = (event) => {

        localStorage.removeItem('access_token');
        window.location.href='/';
    }

    render() {
        if(this.state.logout) {
          return <Redirect to='/' />
        }
         
        return (
            <section> 
            <aside id="leftsidebar" className="sidebar"> 
                <div className="user-info">
                    <div className="image">
                        <img src="/img/user.png" width="48" height="48" alt="User" />
                    </div>
                    <div className="info-container">
                        <div className="name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.props.dashboard.user && this.props.dashboard.user.name}</div>
                        <div className="email">{this.props.dashboard.user && this.props.dashboard.user.email}</div> 
                    </div>
                </div> 
                <div className="menu">
                    <ul className="list">
                        <li className="header">MAIN NAVIGATION</li>
                        <li className="active">
                             <Link to="/dashboard"><i className="material-icons">home</i>Dashboard</Link> 
                        </li>
                        {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&  
                        <li>
                            <Link to="/courses/list"><i className="material-icons">list</i>Courses</Link>
                        </li>
                         }
                          {(this.props.dashboard.user && this.props.dashboard.user.id==1) && 
                        <li>
                            <Link to="/subjects/list"><i className="material-icons">list</i>Subjects</Link>
                        </li>
                         }
                         <li>
                            <Link to="/schools/list"><i className="material-icons">school</i>Study Center</Link>
                        </li>
                        <li>
                            <Link to="/students/list"><i className="material-icons">school</i>Students</Link>
                        </li>
                        {(this.props.dashboard.user && this.props.dashboard.user.id==1) && 
                        <li>
                            <Link to="/users/list"><i className="material-icons">list</i>Users</Link>
                            
                        </li>
                        }
                         {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                        <li>
                        <Link to="/students/print-marksheet"><i className="material-icons">list</i>Print Marksheet</Link>                          
                    </li>
                         }
                          {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                        <li>
                            <Link to="/students/print-migration"><i className="material-icons">list</i>Migration Certificate</Link>                            
                        </li>
                          }
                        {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                         <li>
                         <Link to="/users/change_password"><i className="material-icons">list</i>Change Password</Link>
                            
                        </li>
                        }
                        <li>
                            <a onClick={this.onItemClick}><i className="material-icons">logout</i>Logout</a>
                            
                        </li>
                    </ul>
                </div>
               
            </aside>
            
        </section>
        )
    }
}
export default Menu;