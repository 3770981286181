import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {SCHOOL_PAGE_LOADED,ADD_SCHOOL,DELETE_SCHOOL, SUBJECT_LOAD_AND_COURSE} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.school 
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => 
     payload.then(res=> dispatch({ type: SCHOOL_PAGE_LOADED, data:res[0] }) ),  
     
});

class SchoolsList extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",            
            isLoading: true,
            currentPageNumber:0,
            loginClass: ''
        };
        
    }
    loadData = (page,actionCheck) => {
        this.setState({ isLoading: true ,currentPageNumber:page});
        var result ='actionCheck='+actionCheck;
        this.props.onLoad(Promise.all([school_api.Schools.all(page,result)]));
    }
    componentDidMount() {
         this.loadData(0);
    }

     updateStatus = (status,sid) =>{
        var object = JSON.stringify({status:status,sid:sid});
         
        this.loadData(this.state.currentPageNumber,object);
    }

    deleteCenter = (id) => {
        this.setState({ isLoading: true }); 
        if(window.confirm("Are sure want to delete school and their students?")) {
            school_api.Schools.del(id).then(res => {
                this.setState({ isLoading: false }); 
                this.props.onLoad(Promise.all([school_api.Schools.all(0)])); 
            });
        }
    }
  shouldComponentUpdate(nextProps){
    if(nextProps.schools && this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
    return true;
  }

    render() {
         
        return ( 
           
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    List 
                                    {this.props.addSchool && 
                                     <Link to="/schools/add" className="btn btn-danger waves-effect">Add</Link>
                                    }
                                </h2>
                                
                            </div>
                            <div className="body">
                                <div className="table-responsive">

                                    <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Name</th>
                                                <th>Total Student</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Pincode</th>
                                                <th>Status</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                     
                                        <tbody>
                                        {this.props.schools && this.props.schools.map(item=>(
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                
                                                <td>{item.totalStudent}</td>
                                                <td>{item.state}</td>
                                                <td>{item.city}</td>
                                                <td>{item.pin_code}</td>
                                                <td><a href="javascript:" onClick={()=>this.updateStatus(item.status,item.id)} > {item.status}</a>
                                                </td>
                                                <td>{item.created_at}</td>

                                                <td>
                                                {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                                                <div> <Link to={`/schools/edit/${item.id}`} title="Edit"   ><i class="material-icons">edit</i></Link>

                                                 <a  href="javascript:" onClick={() => this.deleteCenter(item.id)} title="Delete" ><i class="material-icons">delete</i></a>
                                                 </div>
                                                }
                                                
                                                <Link to={`/schools/student/${item.id}`}  title="Add Student" ><i class="material-icons">add</i></Link> 
                                                </td>
                                            </tr>
                                        ))}
                                             
                                        </tbody>
                                    </table>
                                </div>
                            <Pagination current_page={this.props.current_page} total={this.props.total} last_page={this.props.last_page} loadData={this.loadData} />
                            </div>
                        </div>
                        <Loader isloading={this.state.isLoading}/>
                    </div> 
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsList);
