import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Header extends React.Component {
    render () {
        return (
            <div> 
           {/* <div className="page-loader-wrapper">
                <div className="loader">
                    <div className="preloader">
                        <div className="spinner-layer pl-red">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div>
                            <div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                    <p>Please wait...</p>
                </div>
        </div> */}
            <div className="overlay"></div> 
            <div className="search-bar">
                <div className="search-icon">
                    <i className="material-icons">search</i>
                </div>
                <div className="close-search">
                    <i className="material-icons">close</i>
                </div>
            </div> 
            <nav className="navbar">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <a  className="navbar-toggle collapsed" ></a>
                        <a  className="bars"></a>
                        <a className="navbar-brand" href="/dashboard">ADMINBSB - MATERIAL DESIGN</a>
                    </div>
                    
                </div>
            </nav>
            </div>
        );
    }
}
export default Header; 
