import React from "react";
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import {ADD_USER, GET_USER, UPDATE_USER} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader";

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: ADD_USER, ...payload }),
  onEdit: payload =>
    dispatch({ type: GET_USER, ...payload }),
  onUpdate: payload =>
    dispatch({ type: UPDATE_USER, ...payload })
});

const mapStateToProps = state => ({
  ...state.user
}); 

class ChangePassword extends React.Component {
    constructor() {
        super();
        this.state = {
            c_pass:'',
            n_pass:'', 
            redirect:false,
            isLoading: false,
            mode:'edit'
        };
        
        this.setName = ev => {
            this.setState({name: ev.target.value });
        };
        


        this.submitForm = () => ev => {
          ev.preventDefault(); 
          this.setState({isLoading:true});
          let postObject = {...this.state};
          this.saveStudentForm(postObject);
        };

    }

    saveStudentForm = (data) => {
      let payload = ''; 
            payload = school_api.Users.changePassword(this.props.edit_id, data); 
      payload.then(
      res=>{
        this.setState({isLoading:false});
               alert(res.message)
        },
      error=>
          {                
              this.dispatchAction(error.response.body)
          } 
      );
    }

    dispatchAction = (res) => { 
       
    }

    changeValue=(variable) => (ev) => {
      const obj = this.state;
      var value = ev.target.value;
      obj[variable] = value;
      this.setState(obj);
  }

    componentDidMount() {
        
    }

   shouldComponentUpdate(nextProps){
    
    return true;
  }

    render() {
         if(this.state.redirect) {
          return <Redirect to='/users/list' />
        }
        console.log(this.props);
        return (
            
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Change Password 
                            </h2>
                            
                        </div>
                        <div className="body">
                            <div>
                            { this.props.dataErrors && 
                                <ul>
                                {this.props.dataErrors && this.props.dataErrors.map( error=>(
                                    <li key={error}>{error} </li>
                                ))}
                                </ul>  
                             }
                            </div>
                            <form onSubmit={this.submitForm()}>
                                 
                            <label htmlFor="email_address">Currnet Password</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="password" id="c_pass" value={this.state.c_pass} onChange={this.changeValue('c_pass')} className="form-control" placeholder="Password" />
                                    </div>
                                </div>
                                 

                                <label htmlFor="email_address">Password</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="password" id="n_pass" value={this.state.n_pass} onChange={this.changeValue('n_pass')} className="form-control" placeholder="Password" />
                                    </div>
                                </div>
 

                                <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                            </form>
                        </div>
                    </div>
                     <Loader isloading={this.state.isLoading}/>
                </div>
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
