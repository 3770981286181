import {
  ADD_COURSE,
  COURSE_PAGE_LOADED,
  DELETE_COURSE,
  GET_COURSE,
  UPDATE_COURSE
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case COURSE_PAGE_LOADED: 
      return {
        ...state,
        loading:false,
        pages: action.pages ? action.pages : 0,
        datas: action.data.items,
        current_page: action.data.list.current_page,
        total: action.data.list.total,
        last_page: action.data.list.last_page, 
      };
    case GET_COURSE:
    case UPDATE_COURSE:
    case ADD_COURSE:  
      return {
        ...state,
        data:action.data,
        action:action.type,
        success:action.success ? true : false,
        dataErrors: action.errors ? action.errors : null
         
      };
    case DELETE_COURSE:
      const itemId = action.itemId
      return {
        ...state,
        datas: state.datas.filter(data => data.id !== itemId)
      };
    default:
      return state;
  }
};
