import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Dailogs from "../Dailogs/Dailogs";

class Dashboard extends React.Component {
    constructor(){
        super();
    }
    componentDidMount() {
        
   }
    render () {
         if(typeof this.props.dashboard.students == undefined) {
             return (
                 <h1>Please wait...</h1>
             )
         }

       

        return (
            <section className="content">
            <div className="container-fluid">
                <div className="block-header">
                    <h2>DASHBOARD</h2>
                </div>
                
                <div className="row clearfix">
                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                   Latest Students List    
                                </h2>
                                
                            </div>
                            <div className="body">
                                <div className="table-responsive">

                                    <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Study center code</th>
                                                <th>Course</th>
                                                <th>Session</th>
                                                <th>Roll no</th>  
                                                <th>Name</th> 
                                                <th>Created</th> 
                                            </tr>
                                        </thead>
                                     
                                        <tbody>
                                        {this.props.dashboard.students && this.props.dashboard.students.map( (item,i)=>(
                                            <tr key={item.id}>
                                                <td>{i+1}</td>
                                                <td>{item.pin_code}</td>
                                                <td>{item.course.name}</td>
                                                <td>{item.session}</td>
                                                <td>{item.roll_no}</td>
                                                <td>{item.student.name}</td> 
                                                <td>{item.created_at}</td>
                                                
                                            </tr>
                                        ))}

                                        {(this.props.dashboard.students && this.props.dashboard.students.length==0) && 
                                            <tr >
                                                <td colspan="7">No Student Found</td>
                                                 
                                                
                                            </tr>
                                        }
                                             
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                         
                    </div>
                </div>  
                
            </div>
        </section>
        );
    }
}
export default Dashboard;
/* const mapStateToProps = ( state ) => ( {
    loggedIn: state.loggedIn,
} );

export default connect( mapStateToProps )( Header ); */
