import React from "react"; 
 

class Loader extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: ''
        };
        
    }

    componentDidMount() { 
    }

    render() {
       if(this.props.isloading == true)  {
        return ( 
                <div className="page-loader-wrapper">
                <div className="loader">
                    <div className="preloader">
                        <div className="spinner-layer pl-red">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div>
                            <div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                    <p>Please wait...</p>
                </div>
               </div> 
        );
     }else{
        return '';
     }
    }
}

export default Loader;
