import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {SUBJECT_PAGE_LOADED,ADD_COURSE,DELETE_SUBJECT} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";

const mapStateToProps = state => ({
  ...state.subject
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => 
     payload.then(res=> dispatch({ type: SUBJECT_PAGE_LOADED, data:res[0] }) ), 
     
});


class SubjectList extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: '',
             isLoading:true
        };
        
    }
    loadData = (page) => {
        this.setState({ isLoading: true });
        this.props.onLoad(Promise.all([school_api.Subjects.all(page)]));
    }
    componentDidMount() { 
        this.loadData(1);
    }

  shouldComponentUpdate(nextProps){

    if(nextProps.datas && this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
    return true;
  }

    render() {
        
        return ( 
           
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    List | <Link to="/subjects/add" className="btn btn-danger waves-effect">Add</Link>
                                </h2>
                                
                            </div>
                            <div className="body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Name</th> 
                                                <th>Course</th>
                                                <th>Passing Marks</th>
                                                <th>Status</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                     
                                        <tbody>
                                        {this.props.datas && this.props.datas.map(item=>(
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>                                                
                                                <td>{item.course && item.course.name}</td>
                                                <td>{item.passing_mark}</td>
                                                <td>{item.status}</td> 
                                                <td>{item.created_at}</td>
                                                <td>
                                                <Link to={`/subjects/edit/${item.id}`}   ><i class="material-icons">edit</i></Link>
                                               {/* &nbsp;|&nbsp;
                                                <Link to={`/schools/delete/${item.id}`}   >Delete</Link>*/}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination current_page={this.props.current_page} total={this.props.total} last_page={this.props.last_page} loadData={this.loadData} />
                            </div>
                        </div>
                        <Loader isloading={this.state.isLoading}/>
                    </div>
                
         
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SubjectList);
