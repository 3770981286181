import React from "react";
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import {ADD_SCHOOL, GET_SCHOOL, UPDATE_SCHOOL} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader";

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: ADD_SCHOOL, ...payload }),
  onEdit: payload =>
    dispatch({ type: GET_SCHOOL, ...payload }),
  onUpdate: payload =>
    dispatch({ type: UPDATE_SCHOOL, ...payload })
});

const mapStateToProps = state => ({
  ...state.school
});


class SchoolAdd extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '' ,
            id:'',
            city:'',
            address:'',
            pin_code:'',
            state:'',
            status:'Active',
            isLoading:false,
            redirect:false,
            mode:'edit'
        };

        this.setName = ev => {
            this.setState({name: ev.target.value });
        };
        this.setvalueState = ev => {
            this.setState({state: ev.target.value });
        };
        this.setCity = ev => {
            this.setState({city: ev.target.value });
        };
        this.setAddress = ev => {
            this.setState({address: ev.target.value });
        };

        this.setPincode = ev => {
            this.setState({pin_code: ev.target.value });
        };

        this.setStatus = ev => {
            this.setState({status: ev.target.value });
        };

        this.submitForm = () => ev => {
          ev.preventDefault(); 
          this.setState({isLoading:true});
         if(this.props.sid) {
            const payload = school_api.Schools.update(this.props.sid, this.state);
              payload.then(
                res=>{ 
                    this.props.onUpdate(res);
                    this.setState({redirect:true});
                  }, 

                error=>{this.props.onUpdate( error.response.body)} 
              ); 
         }else{
             const payload = school_api.Schools.create( this.state);
              payload.then(
                res=>{ 
                    this.props.onSubmit(res);
                    this.setState({redirect:true});
                  }, 

                error=>{this.props.onSubmit( error.response.body)} 
              ); 
         }
        };
       
    }

    getEditRecrod =() =>{
        if(this.props.sid) {
            console.log('dsfsdf');
            this.setState({ isLoading: true });
           school_api.Schools.get(this.props.sid).then(res=>this.props.onEdit(res)) ;
       }
    }

    componentDidMount() {
        
        this.getEditRecrod();
    }

   shouldComponentUpdate(nextProps){
   
    if(nextProps.action && this.state.isLoading == true) {
      if(this.props.success && this.state.mode=='edit' && (this.props.action != GET_SCHOOL)) {
        this.setState({ isLoading: false ,mode:'done'});
      }else{
        this.setState({ isLoading: false });
      }
    }

    if(this.props.sid!="" && nextProps.action == GET_SCHOOL && this.state.id !=nextProps.school.id ) {
        this.setState({...nextProps.school,isLoading:false});
    }
    return true;
  }
     
    render() {
        
        if(this.state.redirect) {
          return <Redirect to='/schools/list' />
        }

        const name =  this.state.name;
        const state =  this.state.state;
        const city =  this.state.city;
        const address =  this.state.address;
        const pin_code = this.state.pin_code;
        const active = this.state.status == 'Active' ?true:false;
        const inactive = this.state.status == 'Inactive' ?true:false;

        return (
                


                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Form | <Link to="/schools/list" className="btn btn-danger waves-effect">Back</Link>
                            </h2>
                            
                        </div>
                        <div className="body">
                            <div>

                             { this.props.schoolErrors && 
                                <ul>
                                {this.props.schoolErrors && this.props.schoolErrors.map( error=>(
                                    <li key={error}>{error} </li>
                                ))}
                                </ul>  
                             }

                            </div>
                            <form onSubmit={this.submitForm()} >
                                <label htmlFor="email_address">Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="name" className="form-control" placeholder="Enter your name" 
                                        value={name}
                                        onChange={this.setName}

                                         />
                                    </div>
                                </div>
                                <label htmlFor="password">State</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="state" className="form-control" placeholder="Enter your state" value={state}
                                        onChange={this.setvalueState} />
                                    </div>
                                </div>

                                <label htmlFor="password">City</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="city" className="form-control" placeholder="Enter your City" value={city}
                                        onChange={this.setCity} />
                                    </div>
                                </div>
                                <label htmlFor="password">Study Center Code</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="pin_code" className="form-control" placeholder="Enter your Pincode" value={pin_code}
                                        onChange={this.setPincode} />
                                    </div>
                                </div>
                                <label htmlFor="password">Address</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <textarea name="address"  className="form-control" placeholder="Enter your address" value={address}
                                        onChange={this.setAddress} ></textarea>
                                    </div>
                                </div>

                                <label htmlFor="password">Status</label>
                                <div className="form-group">
                                    <div  >
                                        <input type="radio" name="radio" name="status" checked={active}  value="Active"
                                        onChange={this.setStatus} />Active
                                         &nbsp;
                                         <input type="radio" name="radio" name="status" checked={inactive} value="Inactive"
                                        onChange={this.setStatus} />Inactive
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                            </form>
                        </div>
                    </div>
                    <Loader isloading={this.state.isLoading}/>
                </div>
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolAdd);
