import React from "react"; 
import {Modal,Button,Container,Row,Col,ButtonToolbar} from 'react-bootstrap';

 
class Popup extends React.Component {
    
    constructor() {
        super();
        this.state = {
            show:true,
            setShow:true
        };
        
    }

    componentDidMount() { 
    }
    handleShow= (data) => {
        if(data == true && this.state.show == false) {
           this.setState({show:true});
        }
    }
    handleClose= (data) => {
        if(data == false && this.state.show == true) {
            this.setState({show:false});
        }
    }
    
    render() {
         
        return (
            <>
            <Button variant="primary" onClick={this.handleShow(true)} >
              Launch demo modal
            </Button>
      
            <Modal show={this.state.show} onHide={this.handleClose()}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose()} >
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleClose()}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
    }
}

export default Popup;
