import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchLoginData} from "../../store"; 
import Loader from "../Loader/Loader"

class Login extends React.Component {

    constructor() {
        super();
        this.state = {email:"",password:"",isLoading:false}; 
            
        this.submitForm = (email, password) => ev => {
          ev.preventDefault();  
          this.setState({isLoading:true}); 
          this.props.onSubmit(email, password);
        };
    }
     
   

  shouldComponentUpdate(nextProps){
      
    if(nextProps.userLogin.success == false && this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }

    if(nextProps.userLogin.success == true && this.state.isLoading == true) {
        //this.props.updateLogin(true); 
    }
    return true;
  }

    changeEmail(ev) {
        this.setState({
            email:ev.target.value
        });
    }

    changePassword(ev) {
        this.setState({
            password:ev.target.value
        });
    }
    
   

    render () { 
        const email = this.state.email;
        const password = this.state.password;

       if( this.props.isLoggedIn == true  || this.props.userLogin.success) {
            
            return window.location.href="/dashboard";
       }

        return (
        <div className="login-box"> 
        <div className="logo">
            <a href="javascript:void(0);">Login</a>  
        </div>
        <div className="card">
            <div className="body">
            <form onSubmit={this.submitForm(email, password)} >
                    <div>
                     <div className="msg error">{this.props.userLogin.message}</div>
                     <div className="msg">Sign in to start your session</div>
                     </div>
                    <div className="input-group">
                        <span className="input-group-addon">
                            <i className="material-icons">person</i>
                        </span>
                        <div className="form-line">
                            <input name="email" value={email} className="form-control" onChange={(e) => {this.changeEmail(e)}} /> 
                        </div>
                    </div>
                    <div className="input-group">
                        <span className="input-group-addon">
                            <i className="material-icons">lock</i>
                        </span>
                        <div className="form-line">
                          <input name="email" type="password" value={password} className="form-control" onChange={(e) => {this.changePassword(e)}}  /> 
                        </div>
                         
                    </div>
                    <div className="row">
                        <div className="col-xs-8 p-t-5">
                            <input type="checkbox" name="rememberme" id="rememberme" className="filled-in chk-col-pink" />
                            <label for="rememberme">Remember Me</label>
                        </div>
                        <div className="col-xs-4">
                            <button className="btn btn-block bg-pink waves-effect" type="submit" disabled={this.props.userLogin.inProgress} >SIGN IN</button>
                        </div>
                    </div>
                    </form>
            </div>
        </div>
         <Loader isloading={this.state.isLoading}/>
      </div>
         
        );

    }
}

//fetchCircuits( ).then( res => dispatch( storeData( res ) ) );

const mapStateToProps = (state) => ({
    userLogin: state.userLogin,
    isLoggedIn: state.isLoggedIn, 
 });

const mapDispatchToProps =  {
  onSubmit: (email, password) => fetchLoginData(email, password)
};

export default connect( mapStateToProps , mapDispatchToProps)( Login );
