import React from "react";
import StudentsList from "./StudentsList"; 
import StudentEdit from "./StudentEdit";
import PrintMarkSheet from "./PrintMarkSheet";
import PrintMigration from "./PrintMigration";
import {SCHOOL_PAGE_LOADED,ADD_SCHOOL,DELETE_SCHOOL, SUBJECT_LOAD_AND_COURSE} from "../../constants/actionTypes";
import { connect } from 'react-redux';
import school_api from "../../school_api" 
 

const mapStateToProps = state => ({
  ...state.school 
});
const mapDispatchToProps = dispatch => ({
  onSubjectLoad: payload => 
     payload.then(res=> dispatch({ type: SUBJECT_LOAD_AND_COURSE, data:res[0] }) ),
     
});
class Students extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: ''
        };
        
    }

    componentDidMount() {
        console.log(this.props);
         this.props.onSubjectLoad(Promise.all([school_api.Schools.getSubject()]));
    }

    render() {
        
        return (
            <section className="content">
            <div className="container-fluid">
            <div className="block-header">
                <h2>Students</h2>
            </div> 
            <div className="row clearfix">
                {this.props.match.params.action == 'print-migration' && <PrintMigration dashboard={this.props.dashboard} school_id={0}  />}

                {this.props.match.params.action == 'print-marksheet' && <PrintMarkSheet dashboard={this.props.dashboard} school_id={0}  />}

                {this.props.match.params.action == 'list' && <StudentsList dashboard={this.props.dashboard} school_id={0}  />}

                {this.props.match.params.action == 'edit' && <StudentEdit dashboard={this.props.dashboard} course={this.props.course} subjects={this.props.subjects} school_id={0} student_id={this.props.match.params.id}  />}
            </div>
            </div>
            
        </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Students);
